import React from 'react'
import ContactUs from '../components/contact/Contact'
import ContactHero from '../components/hero/ContactHero'

const Contact = () => {
  return (
    <div>

        <ContactHero />
        <ContactUs/>

    </div>
  )
}

export default Contact